import Layout from 'components/Layout'
import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import { shortcodes } from '../../mdxGlobalComponents'
import cntl from 'cntl'
import { prepareData } from 'utils/googleData'
import { getSeoImage } from 'utils/googleData'

// export const Head = ({ location, params, data: { pageData }, pageContext: { documentationNav } }) => (
//     <SEO2
//         title={'About iCloudReady'}
//         description={`iCloudReady is building the world's complete Real estate platform.`}
//         image={`/og-images/about.jpeg`}
//     />
// )
export const section = cntl`
    max-w-6xl
    xl:max-w-7xl
    mx-auto
    px-4
`
export const SectionWrapper = ({ children, className = '' }) => {
    return (
        <section className={`${className} mb-12 mt-12 md:px-4`}>
            <div>{children}</div>
        </section>
    )
}

export default function About(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { pageData, blogPosts, documentation, tutorials, customers } = data
    const result = pageData?.nodes[0] || {}
    const { body, excerpt, parent, fields } = result
    const frontmatter = result?.frontmatter || {}
    const { seoTitle, seoDescription, seoimage } = frontmatter
    const components = {
        ...shortcodes(pageProps),
    }
    return (
        <Layout>
            <SEO
                title={seoTitle || `iCloudReady - The complete Real estate platform`}
                description={seoDescription || `iCloudReady is building the world's complete Real estate platform..`}
                image={getSeoImage({ googleImages: [parent?.images, parent?.images2] }) || `/og-images/about.jpeg`}
            />
            <MDXProvider components={components}>
                <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
        </Layout>
    )
}
