import { graphql } from 'gatsby'
import About from 'components/About/About'

export default About
export const query = graphql`
    query getAboutUsDataQuery {
        pageData: allMdx(filter: { fields: { slug: { regex: "/^/about-us/" } }, frontmatter: { title: { ne: "" } } }) {
            nodes {
                body
                id
                internal {
                    contentFilePath
                }
                headings {
                    depth
                    value
                }
                fields {
                    slug
                }
                frontmatter {
                    title
                    logoFileName
                    subtitle
                    description
                    pricingMainCTA {
                        title
                        url
                    }
                    groupsToShow
                    showCTA
                }
                parent {
                    ... on GoogleDocs {
                        id
                        images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        images2: images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 651, height: 289)
                            }
                        }
                    }
                }
            }
        }
    }
`
